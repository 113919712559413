<template>
  <a-modal :title="title" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确定" width="65.5%" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
              <a-button @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-table :columns="columns" size="small" :dataSource="list" :bordered='false' :pagination="false" rowKey="book_id">
        <template slot="index" slot-scope="text, record , index">
            <span v-if="index+1 == list.length">合计</span>
            <span v-else>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>

        <template slot="course_class_date" slot-scope="text, record, index">
          <span v-if="index+1 == list.length"></span>
          <div v-else style="margin-right:5px;line-height:19px">{{ moment(record.course_class_date).format('YYYY-MM-DD【dddd】')}}{{record.course_class_time}}
            <span v-if="record.is_demo">
              <img style="width:18px" src="@/assets/demo.png">
            </span>
          </div>
        </template>

    </a-table>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
  </a-modal>
</template>

<script>
import moment from 'moment'
import ranges from "@/common/mixins/ranges"
import tableMixins from '@/common/mixins/table'

const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '学员名称', width:120, dataIndex: 'student_name', key: 'name',},
  { title: '上课校区', dataIndex: 'studio_id', key: 'studio_id',ellipsis:true},
  { title: '班级名称', dataIndex: 'class_id', key: 'class_id',ellipsis:true},  
  { title: '授课老师', dataIndex: 'teacher_id', key: 'teacher_id' },
  { title: '上课日期', width:260, dataIndex: 'course_class_date', key: 'course_class_date', scopedSlots: { customRender: 'course_class_date' }  },
  { title: '课程名称', dataIndex: 'course_name', key: 'course_name' ,align:"left",ellipsis:true},
  { title: '上课教室', dataIndex: 'room_id', key: 'room_id' ,align:"center"},
  { title: '课时消耗', dataIndex: 'book_costs', key: 'cost_times' ,align:"center"},
  { title: '课消金额', dataIndex: 'book_balance', key: 'class_balance',align:"center"},
]
    export default {
        name:"recordList",
        inject: ['parent'],
        props: {
            item: Object,
            start_date: String,
            end_date: String,
        },
        mixins: [ tableMixins, ranges ],
        data() {
            return {
                loading: false,
                visible: false,
                confirmLoading: false,
                exportLoading: false,
                columns,
                list: [],
                searchData:{},
                studios:[],
                consultants:[],
                title:'',
            }
        },
        created () {
            this.visible = true
            this.title = `课消记录`
        },
        methods: {
            moment,
            async getList() {
                this.loading = true
                this.searchParams.search.start_date = this.start_date
                this.searchParams.search.end_date = this.end_date

                if(this.parent.paramsData.studio_id){
                  this.searchParams.search.studio_id = this.parent.paramsData.studio_id
                }

                if(this.item.studio_id){
                  this.searchParams.search.studio_id = this.item.studio_id
                }

                if(this.item.channel_id){
                  this.searchParams.search.channel_id = this.item.channel_id
                }
                if(this.parent.paramsData.channel_id){
                  this.searchParams.search.channel_id = this.parent.paramsData.channel_id
                }
                if(this.item.employee_id){
                  this.searchParams.search.course_consultant_id = this.item.employee_id
                }

                if(this.course_cate_id){
                  this.searchParams.search.course_cate_id = this.course_cate_id
                }

                if(this.parent.paramsData.course_cate_id){
                  this.searchParams.search.course_cate_id = this.parent.paramsData.course_cate_id
                }

                let res = await this.$store.dispatch('analysisCourseBalanceRevenueListAction',this.searchParams)
                this.list = res.items
                this.list.push({
                  book_id: -1,
                  book_balance: res._total.book_balance,
                  book_costs: res._total.book_costs
                })
                this.pageParams = res._meta
                this.loading = false
            },
            handleOk(){},
            handleCancel(){
                this.parent.hideRecord(0)
            },
            handleTime(e){
              if(e.length>0){
                this.searchParams.search.start_date = moment(e[0]).format("YYYY-MM-DD")
                this.searchParams.search.end_date = moment(e[1]).format("YYYY-MM-DD")
              }else{
                this.searchParams.search.start_date = undefined
                this.searchParams.search.end_date = undefined
              }
            },
            async toExport(){
                this.exportLoading = true
                let exportData = {
                    search:this.searchParams.search,
                }
                let res = await this.$store.dispatch('analysisCourseBalanceRevenueListExportAction', exportData)
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `课消记录.${moment().format('YYYY.MM.DD')}.xlsx`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.exportLoading = false
            },
        },
    }
</script>

<style lang="scss">
  
</style>