<template>
    <div class="staffTaget">
      <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>财务数据分析</a-breadcrumb-item>
            <a-breadcrumb-item>课消统计</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="main-box">
        <div class="clearfix table-tools">
            <div class="search" style="padding-bottom:10px;">
            <a-form layout='inline'>
                    <a-form-item>
                      <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>

                    <a-form-item>
                      <a-radio-group :value="paramsData.filter_column" @change="handleColButChange">
                        <a-radio-button value="money">
                            金额
                        </a-radio-button>
                        <a-radio-button value="count">
                            课时
                        </a-radio-button>
                      </a-radio-group>
                    </a-form-item>

                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,2)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>所属校区</span>
                        </template>
                        <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                    <a-tooltip placement="topLeft" >
                        <template slot="title">
                        <span>课程科目</span>
                        </template>
                        <a-select allowClear placeholder="请选择课程科目" :filterOption="filterOption" @change="handleSubjectChange" style="width: 180px">
                        <a-select-option v-for="(d, index) of courseCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                    </a-tooltip>
                    </a-form-item>
                <a-form-item>    
                  <a-button type="primary" :loading="loading" @click="searchList">搜索</a-button>
                </a-form-item>
            </a-form>
            </div>
        </div>
        <div style="padding:20px" class="dashboard bk-group">
            <a-row class="mb16" :gutter="16">
                  <a-col :lg="24" :xl="24">
                      <income :loading='loading' title='校区课消数据统计'  :item='incomeDate' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                  </a-col>
            </a-row>

            <div style="background:#fff;">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="course_cate_id"
                :columns="course_columns" :dataSource="course_list" :scroll="{ x: RelativeWidth, y:500}">
                </a-table>
            </div>

            <div style="background:#fff;margin:16px 0">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-card size="small" title="校区课消统计">
                  <a-button slot="extra" @click="toExportStudio" size="small" icon="export" :loading="exportLoading">导出</a-button>
                  <a-table size="small" :pagination="false" :bordered='false' rowKey="studio_id"
                  :columns="columns" :dataSource="list" :scroll="{ x: RelativeWidth, y:500 }">
                    <template slot="name" slot-scope="text,record">
                      <div @click="showRecord(record)" style="cursor:pointer;color:#00cca2" class="table-name">{{text}}</div>
                    </template>
                  </a-table>
                </a-card>  
            </div>
            <div style="background:#fff;margin:16px 0">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-card size="small" title="老师课消统计">
                  <a-button slot="extra" @click="toExportEmployee" size="small" icon="export" :loading="exportLoading">导出</a-button>
                  <a-table size="small" :pagination="false" :bordered='false' rowKey="employee_id"
                  :columns="employee_columns" :dataSource="employee_list" :scroll="{ x: RelativeWidth, y:500}">
                    <template slot="name" slot-scope="text,record">
                      <div @click="showRecord(record)" style="cursor:pointer;color:#00cca2" class="table-name">{{text}}</div>
                    </template>
                  </a-table>
                </a-card>  
            </div>
        </div>
      </div>
      <recordList v-if="isRecordList" :item='modalData' :start_date="paramsData.start_date" :end_date="paramsData.end_date"/>
    </div>
</template>
<script>
import ranges from "@/common/mixins/ranges"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from "moment"
import income from './income'
import tableMixins from '@/common/mixins/table'
import recordList from './recordList'
export default {
    provide() {
      return {
        parent: this
      }
    },
    data(){
        return{
            loading:false,
            isRecordList:false,
            exportLoading:false,
            columns:[],
            pageParams:{
                currentPage: 1,
                pageCount: 50,
                perPage: 10,
                totalCount: 0
            },
            paramsData:{
              start_date:moment().startOf('month').format('YYYY-MM-DD'),
              end_date:moment().format('YYYY-MM-DD'),
              studio_id:'',
              course_cate_id:'',
              filter_type:'month',
              filter_column:'money'
            },
            list:[],
            incomeDate:[],
            studios:[],
            filedLength:0,
            courseCategorys:[],
            employee_columns:[],
            employee_list:[],
            course_columns:[],
            course_list:[],
        }
    },
    mixins: [ tableMixins,ranges ],
    components: { moment , DatePicker , income, recordList},
    created(){
      this.paramsData.start_date = moment().startOf('quarter').format('YYYY-MM-DD')
      this.paramsData.end_date = moment().format('YYYY-MM-DD')
      this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
      this.getStudio() 
      this.getCourseCategory()
    },
    computed: {
        RelativeWidth() {
            let width = 800
            if(this.filedLength >10){
              width = 800 + ( this.filedLength - 10 ) * 125
            }
            return width
        }
  },
    methods:{
        moment,
        filterOption(input, option) {
          return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        notBeforeYear(date){
          const today = new Date()
          return date >= today
        },

        async getList(obj) {
            this.loading = true
            this.searchParams.search.year = this.year || Number(moment().format('YYYY'))
            let res = await this.$store.dispatch('analysisCourseBalanceRevenueAction',{search:this.paramsData})
            this.incomeDate = res.data.total_revenue
            let list = res.data.studio_list.rows
            list.forEach(item=>{
                item.total = this.paramsData.filter_column == 'money'?this.filterNum(item.total) : item.total
                item.filed.forEach(it=>{
                    item[it.filed_name] = this.paramsData.filter_column == 'money'?this.filterNum(it.filed_value):it.filed_value
                })
            })
            let week = [
                { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
                { title: '校区名称', width:'160px', dataIndex: 'studio_name',  key: 'studio_name', scopedSlots: { customRender: 'name' }, fixed: 'left',ellipsis:true},
                { title: '合计', width:'120px', dataIndex: 'total', key: 'total',fixed: 'left',align:'right'},
                { title: '占比', width:'80px', dataIndex: 'precent', key: 'precent',fixed: 'left',align:'right'},
            ]
            if(list[0]){
              list[0].filed.forEach(item=>{
                  let obj = { title: item.filed_name, dataIndex: item.filed_name, key: item.filed_name,align:'right',width:'110px',sorter: (a, b) => Number(a[item.filed_name].replace(/,/g, '')) - Number(b[item.filed_name].replace(/,/g, ''))}
                  week.push(obj)
              })
            }
            let employee_list = res.data.employee_list.rows
            employee_list.forEach(item=>{
                item.total = this.paramsData.filter_column == 'money'?this.filterNum(item.total):item.total
                item.filed.forEach(it=>{
                    item[it.filed_name] = this.paramsData.filter_column == 'money'?this.filterNum(it.filed_value):it.filed_value
                })
            })
            let employee_week = [
                { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
                { title: '授课老师', width:'160px', dataIndex: 'employee_name',  key: 'name', fixed: 'left',scopedSlots: { customRender: 'name' },ellipsis:true},
                { title: '合计', width:'120px', dataIndex: 'total', key: 'total',fixed: 'left',align:'right'},
                { title: '占比', width:'80px', dataIndex: 'precent', key: 'precent',fixed: 'left',align:'right'},
            ]
            if(employee_list[0]){
              employee_list[0].filed.forEach(item=>{
                  let obj = { title: item.filed_name, dataIndex: item.filed_name, key: item.filed_name,align:'right', width:'90px'}
                  employee_week.push(obj)
              })
            }
            let course_list = res.data.course_list.rows
            course_list.forEach(item=>{
                item.total = this.paramsData.filter_column == 'money'?this.filterNum(item.total):item.total
                item.filed.forEach(it=>{
                    item[it.filed_name] = this.paramsData.filter_column == 'money'?this.filterNum(it.filed_value):it.filed_value
                })
            })
            let course_week = [
                { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
                { title: '课程科目', width:'160px', dataIndex: 'course_cate_name',  key: 'name', fixed: 'left',ellipsis:true},
                { title: '合计', width:'120px', dataIndex: 'total', key: 'total',fixed: 'left',align:'right'},
                { title: '占比', width:'80px', dataIndex: 'precent', key: 'precent',fixed: 'left',align:'right'},
            ]
            if(course_list[0]){
              course_list[0].filed.forEach(item=>{
                  let obj = { title: item.filed_name, dataIndex: item.filed_name, key: item.filed_name,align:'right', width:'90px'}
                  course_week.push(obj)
              })
            }
            if(list[0]){
              this.filedLength = list[0].filed.length
            }else{
              this.filedLength = 0
            }
            this.columns = week
            this.list = list
            this.employee_columns = employee_week
            this.employee_list = employee_list
            this.course_columns = course_week
            this.course_list = course_list
            this.loading = false
        },
        async getStudio() {
            let res = await this.$store.dispatch('searchBelongStudioAction', {})
            this.studios = res.data
        },
        async getCourseCategory() {
          let res = await this.$store.dispatch('searchCourseTypeAction', {})
          this.courseCategorys = res.data
        },
        async toExportStudio(){
            this.exportLoading = true
            let exportData = {
                search:this.paramsData
            }
            let res = await this.$store.dispatch('analysisCourseBalanceExportStudioAction', exportData)
            const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
            if ('download' in document.createElement('a')) { 
                const link = document.createElement('a')
                link.download = `校区课消统计.${moment().format('YYYY.MM.DD')}.xlsx`
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                document.body.appendChild(link)
                link.click()
                URL.revokeObjectURL(link.href) 
                document.body.removeChild(link)
            } else { //其他浏览器
                navigator.msSaveBlob(blob, fileName)
            }

            this.exportLoading = false
        },
        async toExportEmployee(){
            this.exportLoading = true
            let exportData = {
                search:this.paramsData
            }
            let res = await this.$store.dispatch('analysisCourseBalanceExportEmployeeAction', exportData)
            const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
            if ('download' in document.createElement('a')) { 
                const link = document.createElement('a')
                link.download = `老师课消统计.${moment().format('YYYY.MM.DD')}.xlsx`
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                document.body.appendChild(link)
                link.click()
                URL.revokeObjectURL(link.href) 
                document.body.removeChild(link)
            } else { //其他浏览器
                navigator.msSaveBlob(blob, fileName)
            }

            this.exportLoading = false
        },
        handleChange(value){
          this.paramsData.studio_id = value;
        },
        handleButChange(e){
          this.paramsData.filter_type = e.target.value
          this.toScreen()
        },
        handleSubjectChange(value){
          this.paramsData.course_cate_id = value;
        },
        handleColButChange(e){
          this.paramsData.filter_column = e.target.value
          this.toScreen()
        },
        toScreen(){
          let obj = {
            search:this.paramsData
          }
          this.getList(obj)
        },
        // 获取一个月有几周
        getWeeks(year, month) {
            var d = new Date();
            // 该月第一天
            d.setFullYear(year, month-1, 1);
            var w1 = d.getDay();
            if (w1 == 0) w1 = 7;
            // 该月天数
            d.setFullYear(year, month, 0);
            var dd = d.getDate();
            // 第一个周一
            let d1;
            if (w1 != 1) d1 = 7 - w1 + 2;
            else d1 = 1;
            let week_count = Math.ceil((dd-d1+1)/7);
            return week_count;
        },
        filterNum(val){
          val = val.toString().replace(/\$|\,/g,'');
          if(isNaN(val)) {
            val = "0";  
          } 
          let sign = (val == (val = Math.abs(val)));
          val = Math.floor(val*100+0.50000000001);
          let cents = val%100;
          val = Math.floor(val/100).toString();
          if(cents<10) {
            cents = "0" + cents
          }
          for (var i = 0; i < Math.floor((val.length-(1+i))/3); i++) {
              val = val.substring(0,val.length-(4*i+3))+',' + val.substring(val.length-(4*i+3));
          }

          return (((sign)?'':'-') + val + '.' + cents);
        },
        showRecord(record){
          this.modalData = record
          this.isRecordList = true
        },
        hideRecord(){
          this.isRecordList = false
        },
    }
}
</script>
<style lang="scss">
  .staffTaget{
    .ant-table-row{
      td:nth-child(2){
        background: rgb(229, 244, 254);
      }
      td:nth-child(3){
        background: rgb(229, 244, 254);
      }
      td:nth-child(4){
        background: rgb(229, 244, 254);
      }
    }
    .table-name{
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>